'use strict';

angular.module('cpformplastApp.items')
    .factory('Item', function(Category, ItemEfficiencyReport) {

    class Item {
        constructor({id, mold, client, code, name, quantityPerBox, quantityPerSkid, ups, archive, category, sheetsPerHour, poundsPerUnit, unitsPerDay, poundsPerDay, efficiencyReports, dimensions, appropriateMachinesId, adjustedStroke}) {
          this.id = id;
          this.mold = mold;
          this.client = client;
          this.code = code;
          this.name = name;
          this.quantityPerBox = quantityPerBox;
          this.quantityPerSkid = quantityPerSkid;
          this.ups = ups;
          this.category = new Category(category);
          this.archive = archive;
          this.sheetsPerHour = sheetsPerHour;
          this.poundsPerUnit = poundsPerUnit;
          this.unitsPerDay = unitsPerDay;
          this.poundsPerDay = poundsPerDay;
          this.dimensions = dimensions;
          this.efficiencyReports = efficiencyReports ? efficiencyReports.map(json => new ItemEfficiencyReport(json)) : [];
          this.appropriateMachinesId = appropriateMachinesId;
          this.adjustedStroke = adjustedStroke;
        }

        get setup() {
          return `${this.category.dimensions.gage} ${this.category.dimensions.width} x (${this.dimensions.xRowCount}) ${this.dimensions.stroke} x (${this.dimensions.yRowCount}) = ${this.ups} <span class="pl-2">${Math.round((this.poundsPerUnit * 100) *10000)/100}</span>`;
        }

        getValueFromKey(key) {
          switch(key) {
            case 'mold':
              return this.mold;
            case 'client':
              return this.client.name;
            case 'item':
              return this.name;
            case 'ups':
              return this.ups;
            case 'setup':
              return this.setup;
            case 'material':
              return this.category.dimension + ' ' + this.category.name ;
            case 'sheetsPerHour':
              return this.sheetsPerHour;
            case 'salesman':
              return this.client.salesman.name;
            case 'unitsPerDay':
              return this.unitsPerDay;
            case 'poundsPerDay':
              return this.poundsPerDay;
            default:
              return '';
          }
        }
    }

    return Item;
});
